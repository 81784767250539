<div class="content">
  <h1 class="title">qr-generator</h1>
  <p class="form">
    <mat-form-field appearance="standard">
      <mat-label>Tekst do zakodowania</mat-label>
      <input id="input" matInput [value]="value" (change)="onValueChange($event)">
      <mat-hint>Wprowadź tekst albo adres strony internetowej, do której ma kierować kod QR po zeskanowaniu</mat-hint>
    </mat-form-field>
  </p>
  <p>
    <canvas id="canvas"></canvas>
  </p>
  <p class="buttons">
    <button mat-fab color="primary" (click)="onDownloadClick()">
      <mat-icon>save_alt</mat-icon>
    </button>
    <button mat-fab color="secondary" (click)="onCopyClick()">
      <mat-icon>file_copy</mat-icon>
    </button>
  </p>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="druk3d"
    version="1.1"
    viewBox="0 0 114.0282 48.711911"
    height="48.71191mm"
    width="114.02821mm">
    <defs
      id="defs2">
      <rect
        id="rect835"
        height="136.82738"
        width="175.38095"
        y="68.791664"
        x="36.285713" />
    </defs>
    <g
      transform="translate(-38.462453,-76.544049)"
      id="layer1">
      <g
        style="font-size:25.4px;line-height:0;font-family:Heilig;-inkscape-font-specification:Heilig;white-space:pre;shape-inside:url(#rect835)"
        id="text833"
        aria-label="druk3d. pulawy.pl">
        <path
          id="path849"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 47.276719,103.69142 q -3.393422,0 -5.339902,-2.6355 -1.929255,-2.6355 -1.929255,-7.303607 0,-4.73701 1.963706,-7.372509 1.980931,-2.652726 5.443255,-2.652726 3.634578,0 5.546607,2.82498 h 0.172255 q -0.396186,-2.153186 -0.396186,-3.841284 v -6.166725 h 5.270999 v 26.802861 h -4.030764 l -1.016304,-2.4977 h -0.223931 q -1.791451,2.84221 -5.46048,2.84221 z m 1.843127,-4.185794 q 2.015382,0 2.945559,-1.171333 0.947401,-1.171333 1.033529,-3.979088 v -0.568441 q 0,-3.100588 -0.964627,-4.444176 Q 51.186905,87.999 49.033718,87.999 q -1.756999,0 -2.738852,1.498617 -0.964628,1.481392 -0.964628,4.323598 0,2.842206 0.981853,4.271921 0.981853,1.41249 2.807755,1.41249 z" />
        <path
          id="path851"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 69.532873,83.801494 q 1.06798,0 1.774225,0.15503 l -0.396186,4.92649 q -0.637343,-0.172255 -1.550294,-0.172255 -2.514922,0 -3.927412,1.291911 -1.395264,1.291912 -1.395264,3.617353 v 9.801307 h -5.253774 v -19.2581 h 3.979087 l 0.775147,3.238391 h 0.258383 q 0.895725,-1.619195 2.411568,-2.601048 1.533069,-0.999079 3.32452,-0.999079 z" />
        <path
          id="path853"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 84.350827,97.373708 -0.706245,-2.463245 h -0.275608 q -0.844049,1.343588 -2.394343,2.084285 -1.550294,0.72347 -3.531225,0.72347 -3.393421,0 -5.11597,-1.808676 -1.722549,-1.825902 -1.722549,-5.236549 V 78.115612 h 5.253774 v 11.248244 q 0,2.084284 0.740696,3.135039 0.740696,1.033529 2.359892,1.033529 2.204863,0 3.186716,-1.464166 0.981853,-1.481392 0.981853,-4.892039 v -9.060607 h 5.253774 v 19.258096 z" />
        <path
          id="path855"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 93.1782,92.873813 2.29099,-2.928333 5.39158,-5.856666 h 5.92557 l -7.64812,8.354362 8.11321,10.903734 h -6.06337 l -5.54661,-7.803146 -2.25654,1.808676 v 5.99447 H 88.13114 V 76.544049 h 5.25377 v 11.95449 l -0.27561,4.375274 z" />
        <path
          id="path857"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 125.06925,88.606263 q -0.54961,1.188182 -1.38159,1.961061 -0.80911,0.76448 -1.81403,1.17268 -0.98205,0.3998 -2.113,0.465017 -1.10809,0.05682 -2.27127,-0.17756 l -0.0434,0.0938 q 2.60812,1.62395 3.40889,3.62655 0.808,1.986963 -0.24059,4.253889 -0.69424,1.50086 -1.83442,2.52974 -1.11731,1.02048 -2.67686,1.43784 -1.54391,0.42459 -3.49389,0.16789 -1.92711,-0.26509 -4.2253,-1.32814 -1.84481,-0.85333 -3.32611,-1.91811 -1.48853,-1.049134 -2.60632,-2.325339 l 1.90192,-4.111732 q 0.52997,0.70064 1.1815,1.343631 0.65153,0.642996 1.33921,1.207818 0.69492,0.549187 1.40196,0.990112 0.72268,0.448154 1.39493,0.759112 1.34452,0.62192 2.35137,0.783983 1.00684,0.16206 1.74812,-0.04545 0.74126,-0.207512 1.24941,-0.731626 0.531,-0.532519 0.87812,-1.28295 0.32542,-0.703528 0.28872,-1.40375 -0.0138,-0.708627 -0.48967,-1.403205 -0.45298,-0.702982 -1.39921,-1.406374 -0.94623,-0.703396 -2.47836,-1.412097 l -1.62593,-0.75209 1.56926,-3.392571 1.59467,0.737627 q 1.43833,0.665313 2.5077,0.856297 1.07661,0.175351 1.84192,-0.0021 0.78818,-0.185811 1.29749,-0.671429 0.50931,-0.485616 0.82027,-1.157878 0.56407,-1.219449 0.11619,-2.261694 -0.43223,-1.035012 -2.0738,-1.794335 -0.75043,-0.347118 -1.47937,-0.494506 -0.70608,-0.155793 -1.37738,-0.181624 -0.64843,-0.03423 -1.24762,0.03023 -0.57632,0.05606 -1.07447,0.148278 l -0.94919,-4.348734 q 0.88081,-0.180924 1.8349,-0.233056 0.96971,-0.0449 2.01389,0.07749 1.0514,0.106756 2.17843,0.419303 1.12702,0.312547 2.3152,0.862149 1.68847,0.78102 2.8907,1.792618 1.2251,1.003198 1.88461,2.162317 0.66675,1.14349 0.72825,2.405574 0.0844,1.253681 -0.51586,2.551299 z" />
        <path
          id="path859"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 129.98216,104.58359 q -3.39342,0 -5.3399,-2.6355 -1.92926,-2.6355 -1.92926,-7.303607 0,-4.73701 1.96371,-7.372509 1.98093,-2.652726 5.44325,-2.652726 3.63458,0 5.54661,2.82498 h 0.17226 q -0.39619,-2.153186 -0.39619,-3.841284 v -6.166725 h 5.271 v 26.802861 h -4.03077 l -1.0163,-2.4977 h -0.22393 q -1.79145,2.84221 -5.46048,2.84221 z m 1.84313,-4.18579 q 2.01538,0 2.94556,-1.171337 0.9474,-1.171333 1.03352,-3.979088 v -0.568441 q 0,-3.100588 -0.96462,-4.444176 -0.9474,-1.343588 -3.10059,-1.343588 -1.757,0 -2.73885,1.498617 -0.96463,1.481392 -0.96463,4.323598 0,2.842206 0.98185,4.271921 0.98185,1.412494 2.80776,1.412494 z" />
        <path
          id="path861"
          style="font-size:35.2778px;line-height:0.85;letter-spacing:0px"
          d="m 141.80977,101.32342 q 0,-0.80959 0.22394,-1.360808 0.24115,-0.568441 0.65456,-0.912951 0.41342,-0.34451 0.96463,-0.499539 0.55122,-0.15503 1.18856,-0.15503 0.60289,0 1.13688,0.15503 0.55122,0.155029 0.96463,0.499539 0.41341,0.34451 0.65457,0.912951 0.24116,0.551218 0.24116,1.360808 0,0.77515 -0.24116,1.32637 -0.24116,0.55121 -0.65457,0.91295 -0.41341,0.36173 -0.96463,0.51676 -0.53399,0.17226 -1.13688,0.17226 -0.63734,0 -1.18856,-0.17226 -0.55121,-0.15503 -0.96463,-0.51676 -0.41341,-0.36174 -0.65456,-0.91295 -0.22394,-0.55122 -0.22394,-1.32637 z" />
        <path
          id="path863"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 46.81783,118.83293 q -2.680797,0 -4.204905,-1.94595 h -0.217729 q 0.217729,1.90513 0.217729,2.20451 v 6.16447 h -4.150472 v -21.90905 h 3.37481 l 0.585149,1.97318 h 0.190513 q 1.456068,-2.25895 4.31377,-2.25895 2.694405,0 4.218512,2.08204 1.524108,2.08204 1.524108,5.78345 0,2.43585 -0.721229,4.23212 -0.707622,1.79627 -2.027608,2.73523 -1.319986,0.93895 -3.102648,0.93895 z M 45.5931,106.38152 q -1.537716,0 -2.245337,0.95257 -0.707621,0.93895 -0.734838,3.11625 v 0.44907 q 0,2.44946 0.72123,3.51089 0.734838,1.06143 2.313378,1.06143 2.789661,0 2.789661,-4.59954 0,-2.24533 -0.694013,-3.3612 -0.680405,-1.12947 -2.150081,-1.12947 z" />
        <path
          id="path865"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 66.876177,118.56077 -0.557932,-1.94596 h -0.21773 q -0.666797,1.06144 -1.891526,1.64658 -1.22473,0.57154 -2.789662,0.57154 -2.680797,0 -4.041607,-1.42885 -1.360811,-1.44246 -1.360811,-4.13686 v -9.92031 h 4.150472 v 8.88609 q 0,1.64658 0.585149,2.47668 0.585148,0.81648 1.86431,0.81648 1.741838,0 2.5175,-1.15668 0.775662,-1.1703 0.775662,-3.86471 v -7.15786 h 4.150472 v 15.21386 z" />
        <path
          id="path867"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="M 78.592757,118.56077 H 74.442285 V 97.38656 h 4.150472 z" />
        <path
          id="path869"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 92.595499,118.56077 -0.802879,-2.06843 h -0.108864 q -1.047825,1.31999 -2.163689,1.83709 -1.102257,0.5035 -2.884919,0.5035 -2.190905,0 -3.456458,-1.25194 -1.251946,-1.25195 -1.251946,-3.56532 0,-2.42225 1.687405,-3.56533 1.701013,-1.15669 5.116648,-1.27916 l 2.639972,-0.0817 v -0.6668 q 0,-2.31337 -2.36781,-2.31337 -1.823486,0 -4.286553,1.10225 l -1.374419,-2.80327 q 2.626364,-1.37442 5.824269,-1.37442 3.061824,0 4.694796,1.3336 1.632973,1.33359 1.632973,4.05521 v 10.13804 z m -1.22473,-7.049 -1.605756,0.0544 q -1.809878,0.0544 -2.694405,0.65319 -0.884527,0.59875 -0.884527,1.82348 0,1.75545 2.014,1.75545 1.442459,0 2.299769,-0.8301 0.870919,-0.83009 0.870919,-2.20451 z" />
        <path
          id="path871"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 112.25921,118.56077 -1.1703,-5.32077 -1.55132,-6.7224 h -0.12247 l -2.77606,12.04317 h -4.46346 l -4.327373,-15.21386 h 4.136863 l 1.75545,6.73601 q 0.29938,1.19752 0.83009,4.99418 h 0.10887 q 0.0544,-1.03422 0.47628,-3.27956 l 0.21773,-1.15668 1.87792,-7.29395 h 4.57232 l 1.78266,7.29395 q 0.1633,0.74844 0.39464,2.20451 0.24494,1.44246 0.27216,2.23173 h 0.10887 q 0.14968,-1.21112 0.42185,-2.74884 0.27216,-1.55132 0.46267,-2.24534 l 1.82349,-6.73601 h 4.06882 l -4.38181,15.21386 z" />
        <path
          id="path873"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 119.49805,103.34691 h 4.5451 l 2.87131,8.5595 q 0.36742,1.11586 0.5035,2.63997 h 0.0816 q 0.14969,-1.40163 0.58515,-2.63997 l 2.81688,-8.5595 h 4.44985 l -6.43664,17.15982 q -0.88452,2.38142 -2.5311,3.56533 -1.63298,1.1839 -3.82388,1.1839 -1.07504,0 -2.10926,-0.23134 v -3.29316 q 0.74845,0.17691 1.63298,0.17691 1.10225,0 1.91874,-0.68041 0.83009,-0.6668 1.29277,-2.02761 l 0.24494,-0.74844 z" />
        <path
          id="path875"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 130.21013,116.61481 q 0,-0.63958 0.17691,-1.07504 0.19051,-0.44906 0.5171,-0.72123 0.3266,-0.27216 0.76206,-0.39463 0.43546,-0.12247 0.93896,-0.12247 0.47628,0 0.89813,0.12247 0.43546,0.12247 0.76206,0.39463 0.32659,0.27217 0.5171,0.72123 0.19052,0.43546 0.19052,1.07504 0,0.61237 -0.19052,1.04783 -0.19051,0.43546 -0.5171,0.72123 -0.3266,0.28577 -0.76206,0.40824 -0.42185,0.13608 -0.89813,0.13608 -0.5035,0 -0.93896,-0.13608 -0.43546,-0.12247 -0.76206,-0.40824 -0.32659,-0.28577 -0.5171,-0.72123 -0.17691,-0.43546 -0.17691,-1.04783 z" />
        <path
          id="path877"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 142.8775,118.83293 q -2.68079,0 -4.2049,-1.94595 h -0.21773 q 0.21773,1.90513 0.21773,2.20451 v 6.16447 h -4.15047 v -21.90905 h 3.37481 l 0.58515,1.97318 h 0.19051 q 1.45607,-2.25895 4.31377,-2.25895 2.6944,0 4.21851,2.08204 1.52411,2.08204 1.52411,5.78345 0,2.43585 -0.72123,4.23212 -0.70762,1.79627 -2.02761,2.73523 -1.31998,0.93895 -3.10265,0.93895 z m -1.22473,-12.45141 q -1.53771,0 -2.24533,0.95257 -0.70763,0.93895 -0.73484,3.11625 v 0.44907 q 0,2.44946 0.72123,3.51089 0.73484,1.06143 2.31338,1.06143 2.78966,0 2.78966,-4.59954 0,-2.24533 -0.69402,-3.3612 -0.6804,-1.12947 -2.15008,-1.12947 z" />
        <path
          id="path879"
          style="font-size:27.8694px;line-height:0.85;letter-spacing:0px"
          d="m 152.49066,118.56077 h -4.15048 V 97.38656 h 4.15048 z" />
      </g>
    </g>
  </svg>
  <p class="footer">
    <small>The word "QR Code" is registered trademark of:
    DENSO WAVE INCORPORATED</small>
    <a mat-button href="https://druk3d.pulawy.pl" target="_blank"><mat-icon>copyright</mat-icon> druk3d.pulawy.pl</a>
  </p>
</div>

