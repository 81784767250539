/// <reference types="./typings" />

import { Component, OnInit } from '@angular/core';
import  QRCode from 'qrcode';
import { ReImg } from 'reimg';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'qr-generator';
  value: string = "http://qr.maryniowski.pl";

  ngOnInit() {
    this.generate();
  };
  onValueChange($event): void {
    this.value = $event.target.value;
    this.generate();
  };
  generate(): void {
    const canvas = document.getElementById('canvas');
    QRCode.toCanvas(canvas, this.value, function (error) {
      if (error) console.error(error)
      console.log('success!');
    })
  };
  onDownloadClick(): void {
    ReImg.fromCanvas(document.querySelector('canvas')).downloadPng();
  };
  b64toBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/png' });
  };
  async onCopyClick(): Promise<void> {
    try {
      const blob = ReImg.fromCanvas(document.querySelector('canvas')).toBase64();
      await navigator.clipboard.write([
        new ClipboardItem({
          ['image/png']: this.b64toBlob(blob)
        })
      ]);
    } catch (err) {
      console.error(err.name, err.message);
    }
  }
}
